import React, { Component } from 'react';
import 'typeface-roboto';
import aboutFigure from '../../images/about-figure.png';
import { Button, Container, Grid, Typography } from '../../../../../node_modules/@mui/material/index';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
export class Aboutus extends Component {
    static displayName = Aboutus.name;
    constructor(props) {

        super(props);
        this.state = {
            aboutUsContentP1: "Nami Studio MY is founded in 2022 by a Malaysian Software Engineer who has passion to working and learning on software application in our free time. We like to improve our ability in Software Programming .We are seeking for new experience, challenges and technologies while enjoy continuous learning."

        }
    }
    render() {
        return (
            <div style={{ backgroundColor: '#4A3536' }}>
                <Container sx={{ pt: 15, pb: 15 }}>
                    <Grid container spacing={4}>
                        <Grid item xs={12} sm={4} sx={{ mb: 2 }}>
                            <img class="ui fluid image" src={aboutFigure}  style={{ borderRadius: '10px' }} />
                        </Grid>
                        <Grid item xs={12} sm={8} sx={{ mb: 2, alignContent: 'center' }}>
                            <Typography variant='h4' color='white' sx={{ fontWeight: 'bold', textAlign: 'center' }}>
                                About
                                <font color="#FF001F"> Us</font>
                            </Typography>
                            <hr style={{ width: '20%', margin: 'auto', color: 'white', marginBottom: 50 }}></hr>
                            <Typography variant='h5' color='white' sx={{ mb: 5 }}>
                                {this.state.aboutUsContentP1}
                            </Typography>
                            <a href="/#/our-work">
                                <Button variant="contained" sx={{ backgroundColor: 'red', borderRadius: '100px' }} size='large' endIcon={<DoubleArrowIcon />}>
                                    Our Work
                                </Button>
                            </a>

                        </Grid>
                    </Grid>

                </Container>
            </div>
        );
    }
}
