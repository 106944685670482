import React, { Component } from 'react';
import 'typeface-roboto';
import mainLogo from '../../images/main-logo.png';
import { Typography, Container, Grid, Button } from '../../../../../node_modules/@mui/material/index';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import AdbIcon from '@mui/icons-material/Adb';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';

export class Main extends Component {
    static displayName = Main.name;

    render() {
        return (
            <Container sx={{ mt: 10, mb: 10 }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={4} sx={{ mb: 2 }}>
                        <img class="ui fluid image" src={mainLogo} />
                    </Grid>
                    <Grid item xs={12} sm={8}>
                        <Typography variant='h3' color='white' sx={{ fontWeight: 'bold' }}>
                            Hello, It's Us.
                        </Typography>
                        <Typography variant='h2' color='white' sx={{ fontWeight: 'bold', mt: 4 }}>
                            NAMI STUDIO MY
                        </Typography>
                        <Typography variant='h5' color='white' sx={{ fontWeight: 'bold', mt: 4, mb: 2 }}>
                            Malaysian Software Engineer who has passion to working and learning on software application.
                        </Typography>

                        <Grid container spacing={1} sx={{ mt: 2, mb: 5 }}>
                            <Grid item xs={2} sm={1}>
                                <a href="https://www.facebook.com/profile.php?id=100085342825720" target="_blank" rel="noreferrer">
                                    <FacebookIcon fontSize="large" style={{ color: 'red' }} />
                                </a>
                            </Grid>
                            <Grid item xs={2} sm={1}>
                                <a href="https://www.instagram.com/namistudio.my/" target="_blank" rel="noreferrer">
                                    <InstagramIcon fontSize="large" style={{ color: 'red' }} />
                                </a>
                            </Grid>
                            <Grid item xs={2} sm={1}>
                                <a href="https://play.google.com/store/apps/developer?id=Nami+Apps" target="_blank" rel="noreferrer">
                                    <AdbIcon fontSize="large" style={{ color: 'red' }} />
                                </a>
                            </Grid>
                            <Grid item xs={2} sm={1}>
                                <a href="https://www.linkedin.com/company/nami-studio" target="_blank" rel="noreferrer">
                                    <LinkedInIcon fontSize="large" style={{ color: 'red' }} />
                                </a>
                            </Grid>
                        </Grid>

                        <a href="/#/#aboutus">
                            <Button variant="contained" sx={{ backgroundColor: 'red', borderRadius: '100px' }} size='large' endIcon={<KeyboardDoubleArrowDownIcon />}>
                                About Us
                            </Button>
                        </a>
                    </Grid>
                </Grid>
            </Container>
        );
    }
}
